@import 'styles/mixins';
@import 'styles/vars';

$camera-stream: camera-stream;
$offset: 20px;

.#{$camera-stream} {
  position: relative;
  margin: 30px auto;

  @include flex-column(center, flex-start);

  &__wrapper {
    position: relative;
  }

  &__video {
    object-fit: cover;
    transition: $base-transition opacity;

    @include rect(100%);

    &--visible {
      opacity: 1;
    }

    &--hidden {
      opacity: 0;
    }

    &-wrapper {
      position: relative;
    }
  }

  &__frame {
    position: absolute;
    width: 100%;

    &::before,
    &::after {
      position: absolute;
      display: inline-block;
      content: '';
      border: 1px solid $c-black;

      @include rect(20px);
    }

    &--top {
      top: 0;

      &::before {
        top: -1 * $offset;
        left: -1.2 * $offset;
        border-right: none;
        border-bottom: none;
      }

      &::after {
        top: -1 * $offset;
        right: -1.2 * $offset;
        border-left: none;
        border-bottom: none;
      }
    }

    &--bottom {
      bottom: 0;

      &::before {
        bottom: -1 * $offset;
        left: -1.2 * $offset;
        border-right: none;
        border-top: none;
      }

      &::after {
        bottom: -1 * $offset;
        right: -1.2 * $offset;
        border-left: none;
        border-top: none;
      }
    }
  }

  &--flipped {
    .#{$camera-stream}__video {
      transform: scaleX(-1);
    }
  }
}
