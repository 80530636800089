@import 'styles/vars';
@import 'styles/mixins';

.steps {
  position: relative;

  &__list {
    padding: 0;

    @include flex(center, center);
  }

  &__line,
  &__progress {
    position: absolute;
    display: inline-block;
    height: 1px;
  }

  &__line {
    top: 35px;
    left: 35px;
    width: calc(100% - 70px);
    background-color: $c-grey;
  }

  &__progress {
    top: 0;
    left: 0;
    background-color: $c-green;
    transition: width $base-transition;
  }

  &__item {
    position: relative;
    z-index: 1;
    margin: 20px;
    background-color: $c-green;
    color: $c-white;
    font-size: 16px;
    line-height: 1;
    border-radius: 50%;
    transition: all $base-transition;

    @include flex(center, center);
    @include rect(30px);

    &--inactive {
      border: 1px solid $c-grey;
      background-color: $c-white;
      color: $c-black;
    }
  }
}
