@import 'styles/vars';
@import 'styles/mixins';

.doc-option {
  max-width: 360px;
  min-width: 170px;
  margin: 20px 0;
  padding: 70px 35px;
  background-color: $c-white;
  box-shadow: 0 6px 5px 0 $c-grey;
  transition: border-color $base-transition;
  text-align: center;

  @include rounded-border;
  @include flex-column(center, center);

  &:active {
    border: 1px solid $c-black;
  }

  &__icon {
    position: relative;
    margin-bottom: 40px;

    @include rect(160px);
  }

  &__desc {
    margin-top: 0;
  }
}
