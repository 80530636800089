@import 'styles/mixins';
@import 'styles/vars';

.base-view {
  margin: 20px auto;
  padding: 40px 45px;
  background-color: $c-white;

  @include rounded-border;
}
