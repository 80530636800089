@import 'styles/vars';

.base-error {
  text-align: center;

  &__title {
    color: $c-red;
    font-weight: 500;
  }

  &__message {
    margin-top: 30px;
  }
}
