@import 'styles/vars';
@import 'styles/mixins';

.counter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1;

  @include heightVh;

  &__message,
  &__counter {
    opacity: 0.9;
    color: $c-white;
  }

  &__message {
    position: absolute;
    top: 50vh;
    top: calc(var(--vh, 1vh) * 50);
    left: 50%;
    z-index: 3;
    font-size: 25px;
    font-weight: 400;
    line-height: 2;
    transform: translate(-50%, -74%);

    @include flex-column(center, center);
  }

  &__counter {
    display: inline-block;
    padding: 0 10px;
    font-size: 40px;
    font-weight: 700;
    color: $c-white;
  }
}
