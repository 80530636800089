@mixin flex($align: 'flex-start', $justify: 'flex-start') {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-column($align: 'flex-start', $justify: 'flex-start') {
  display: flex;
  flex-direction: column;
  align-items: $align;
  justify-content: $justify;
}
