@import 'styles/mixins';

.next-step {
  padding-top: 10px;
  text-align: center;

  &__img {
    @include rect(117px, 123px);
  }
}
