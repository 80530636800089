@font-face {
  font-family: 'URWDinArabic';
  font-weight: 500;
  src: local('URWDIN'),
    url('../assets/fonts/URWDINArabic-Medium.woff2') format('woff2'),
    url('../assets/fonts/URWDINArabic-Medium.woff') format('woff'),
    url('../assets/fonts/URWDINArabic-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'URWDinArabic';
  font-weight: 300;
  src: local('URWDIN'),
    url('../assets/fonts/URWDINArabic-Light.woff2') format('woff2'),
    url('../assets/fonts/URWDINArabic-Light.woff') format('woff'),
    url('../assets/fonts/URWDINArabic-Medium.woff') format('woff');
}
