@import 'styles/mixins';
@import 'styles/vars';

.fallback {
  padding: 30px 0 60px;

  @include flex-column(center, center);

  &__title {
    color: $c-red;
  }
}
