@import 'styles/mixins';
@import 'styles/vars';

.snapshot-button {
  border: 0;
  background: none;
  padding: 0;

  &__inner {
    display: inline-block;
    border-radius: 30px;
    background-color: $c-green;

    @include rect(60px);
    @include flex(center, center);
  }

  svg {
    @include rect(30px);
  }
}
