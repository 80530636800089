@import 'styles/mixins';
@import 'styles/vars';

.snapshot {
  margin-top: 40px;

  @include flex-column(center, flex-start);

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: none;

    &--visible {
      display: block;
    }
  }

  &__img {
    @include rect(100%);
  }

  &__canvas {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }

  &__button {
    @include flex-column(center, center);
  }
}
