@import 'styles/mixins';
@import 'styles/vars';

.base-image {
  position: relative;

  &,
  &__image {
    @include rect(100%);
  }

  &__image,
  &__fallback {
    object-fit: contain;
    object-position: center;
    opacity: 0;
    position: absolute;
    transition: $base-transition opacity;

    &--visible {
      opacity: 1;
      position: static;
    }

    &--hidden {
      opacity: 0;
      position: absolute;
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include spinner($c-grey);
  }
}
