@import './mixins';
@import './vars';
@import './atomic';
@import './typography';
@import './fonts';

body {
  color: $c-black;
}

#root {
  width: 100vw;
  overflow: hidden;
  background-color: $c-light-grey;
  background-image: url('../assets/img/background.jpg');
  background-position: center;
  background-size: cover;

  @include minHeightVh;
  @include flex-column(center, space-between);
}

.app {
  flex-grow: 1;
  width: 90vw;

  @include container;

  &__header {
    padding-top: 30px;

    @include flex(center, center);
  }

  &__logo {
    position: relative;
    max-width: 200px;

    img {
      width: 100%;
    }
  }
}
