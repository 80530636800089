@import 'styles/mixins';

.snapshot-preview {
  @include flex-column(center, flex-start);

  &__canvas {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
  }
}
