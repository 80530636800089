@import 'styles/vars';
@import 'styles/mixins';

.base-button {
  padding: 14px 20px;
  border: none;
  outline: none;
  min-width: 157px;
  border-radius: 10px;
  transition: opacity $base-transition;

  &--white {
    background-color: $c-white;
    border: 1px solid $c-light-grey;
  }

  &--green {
    background-color: $c-green;
    color: $c-white;

    a {
      color: inherit;
    }
  }

  &--disabled {
    opacity: 0.7;
  }

  &__inner {
    font-size: 16px;
    font-weight: 500;
  }
}
