@import 'styles/vars';

.document {
  position: relative;

  &__title {
    margin-bottom: 55px;
  }

  &__wrapper {
    position: relative;
  }
}
