@import 'styles/mixins';
@import 'styles/vars';

.onboard {
  position: relative;
  padding-bottom: 50px;

  &__steps {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    .dashboard + &,
    &:first-of-type {
      display: none;
    }
  }
}
