.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.center {
  text-align: center;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

$margins: 5;
$paddings: 3;

@for $i from 1 through $margins {
  .m-#{$i * 10} {
    margin: #{$i * 10}px;
  }

  .mt-#{$i * 10} {
    margin-top: #{$i * 10}px;
  }

  .mr-#{$i * 10} {
    margin-right: #{$i * 10}px;
  }

  .mb-#{$i * 10} {
    margin-bottom: #{$i * 10}px;
  }

  .ml-#{$i * 10} {
    margin-left: #{$i * 10}px;
  }
}

@for $i from 1 through $paddings {
  .p-#{$i * 10} {
    padding: #{$i * 10}px;
  }

  .pt-#{$i * 10} {
    padding-top: #{$i * 10}px;
  }

  .pr-#{$i * 10} {
    padding-right: #{$i * 10}px;
  }

  .pb-#{$i * 10} {
    padding-bottom: #{$i * 10}px;
  }

  .pl-#{$i * 10} {
    padding-left: #{$i * 10}px;
  }
}
