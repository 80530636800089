* {
  font-family: $font-main;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  font-weight: 500;
  line-height: normal;
}

h1,
.h1 {
  font-size: 28px;
}

h2,
.h2 {
  font-size: 25px;
}

h3,
h4,
h5,
.h3,
.h4,
.h5 {
  font-size: 20px;
}

p,
.p {
  font-size: 16px;
}

input {
  font-size: 16px;
}

a {
  text-decoration: none;
  color: $c-black;
  font-weight: inherit;
}
