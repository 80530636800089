@import 'styles/mixins';
@import 'styles/vars';

.base-loader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  background-color: $c-black;
  transition: $base-transition opacity;
  z-index: -1;
  pointer-events: none;

  @include flex(center, center);

  &--active {
    opacity: 0.5;
    z-index: 1;
    pointer-events: all;
  }

  &__animated {
    @include spinner;
  }
}
