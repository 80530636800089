/* colors */
$c-black: #484848;
$c-white: #fff;
$c-green: #3aae2a;
$c-grey: #dbdbdb;
$c-light-grey: #f2f2f2;
$c-red: rgb(194, 0, 0);

/* sizes */
$container-pd: 16px;
$main-pd: 110px;

/* font */
$font-main: 'URWDinArabic', -apple-system, 'BlinkMacSystemFont', 'Segoe UI',
  'Roboto', 'Ubuntu', sans-serif;

/* transitions */
$base-transition: 0.3s;
